@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');

body {
  margin: 0;
  padding: 0;
}

/*#region landing*/
.landing {
  height: 100vh;
  background-color: black;
}

.landing video {
  height: 100vh;
  width: 100vw;

  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  object-fit: cover;
}

.landing_text {
  position: relative;
  color: white;

  background-color: rgba(0, 0, 0, 0.8);

  padding-top: 50px;
  padding-bottom: 50px;
  
  bottom: 75vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.landing_text h1 {
  font-family: 'Caladea';
  font-size: 4vmax;

  margin-bottom: 20px;
  padding-bottom: 2vmin;
  border-bottom-width: 1px;
  border-bottom-color: white;
  border-bottom-style: solid;
}

.landing_text h2 {
  font-family: 'Caladea';
  font-size: 1.5vmax;
  margin-bottom: 60px;
}

.landing_text p {
  font-family: 'Roboto';
  font-size: 1.5vmax;
  margin-bottom: 60px;
}

.landing_text button{
  font-family: 'Roboto';
  color: #787878;

  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.landing_text button:hover{
  color: white;
}
/*#endregion landing*/

/*#region portfolio*/
.portfolio {
  height: 100vh;
  background-color: white;
}

.portfolio h1 {
  position: relative;
  top: 5vh;
  width: 100vw;

  text-align: center;
  font-size: 2.5em;

  font-family:'Caladea';
  color:black;
  text-decoration: underline;
}

.portfolioContainer {
  height: 80vh;
  width: 95vw;
  
  margin-top: 8vh;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.portfolioBlock {
  width: 29vw;
  height: 28vh;

  margin-right: 1vw;
  margin-left: 1vw;
  margin-top: 1vw;
  margin-bottom: 1vw;

  border-radius: 20px;

  overflow: hidden;
  box-shadow: 1px 1px 10px black;
  
  display: flex;

  background-color: #142a6e;
}

.portfolioBlock video {
  width: 65%;
  height: 100%;

  margin-bottom: 0;

  object-fit: cover;
}

.portfolioContent {
  height: 100%;
  width: 35%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.portfolioText {
  width: 80%;

  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
}

.portfolioText h1{
  position:static;
  top: 0;
  width: auto;

  color:white;

  margin-bottom: 10%;

  text-align: center;
  text-decoration: underline;
  font-size: 1.3vmax;

  font-family: 'Red Hat Display';
}

.portfolioText p {
  font-size: 0.9vmax;

  color: white;

  font-family: 'Roboto';
}

.portfolioLinks {
  width: 90%;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: space-between;
}

.portfolioLinks img{
  margin-left: 0.1em;
  height: 0.7em;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(300deg) brightness(104%) contrast(102%);

  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.portfolioPlayStore{
  border-radius: 10px;

  margin-bottom: 4%;

  height: 60px;
  
  margin-left: auto;
  margin-right: auto;
}

.portfolioPlayStore img{
  height: 100%;
  filter: none;
}

.portfolioContent a{
  width: 68%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8%;
}

.portfolioContent button{
  border-radius: 10px;
  
  height: 50px;
  width: 100%;

  background-color: #4369e6;
  color: white;

  text-align: center;
  font-family: 'Caladea';
  font-size: 1vmax;

  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.portfolioContent button:hover{
  background-color: white;
  color: #4369e6;
}

.portfolioLinks button:hover img{
  filter: invert(32%) sepia(78%) saturate(1552%) hue-rotate(212deg) brightness(95%) contrast(89%);
}
/*#endregion portfolio*/

/*#region about*/
.about {
  height: 100vh;

  padding-left: 5vw;
  padding-right: 5vw;

  background-color: #142a6e;

  display: flex;
  justify-content: center;
  align-items: center;
}

.about img {
  height: 35vh;
  width: 35vh;
  object-fit: cover;
  border-radius: 50%;
}

.about_text h1{
  font-family: 'Caladea';
  text-decoration: underline;
  font-size: 2.5em;
}

.about_text p, .about_text h1 {
  margin-bottom: 20px;
  margin-left: 5vw;
  margin-right: 5vw;

  color:white;
}

.about_text p {
  line-height: 3vmin;
  font-size: 2vmin;
  font-family: 'Roboto';
}

.about_text a {
  text-decoration: none;
  color: #787878;

  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.about_text a:hover{
  text-decoration: underline;
  color:white;
}

.about_resume {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3%;
}

.about_resume img{
  margin-left: 0.5em;
  height: 0.7em;
  width: auto;
  
  object-fit: none;
  border-radius: 0;

  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(300deg) brightness(104%) contrast(102%);

  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.about_resume button{
  border-radius: 10px;
  
  height: 50px;
  width: 150px;

  background-color: #4369e6;
  color: white;

  text-align: center;
  font-family: 'Caladea';
  font-size: 1vmax;

  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.about_resume button:hover{
  background-color: white;
  color: #4369e6;
}

.about_resume button:hover img{
  filter: invert(32%) sepia(78%) saturate(1552%) hue-rotate(212deg) brightness(95%) contrast(89%);
}
/*#endregion about*/

@media (max-aspect-ratio: 7/5) {/*max-width: 1000px*/
  .landing_text {
    bottom: 85vh;
  }

  .landing_text h1 {
    font-size: 7vmax;
  }

  .landing_text h2 {
    font-size: 2.5vmax;
  }
  
  .landing_text p {
    width: 80%;
    font-size: 3vmax;
    margin-bottom: 60px;
  }
  
  .landing_text button{
    font-family: 'Roboto';
    color: #787878;
  
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  
  .portfolio {
    height: 230vh;
  }
  
  .portfolioBlock {
    width: 100%;
  }
  
  .portfolioText h1{
    font-size: 3vmax;
  }
  
  .portfolioText p {
    font-size: 2vmax;
  }
  
  .portfolioContent button{
    font-size: 1.6vmax;
  }

  .about {
    flex-direction: column;
  }
  
  .about img {
    height: 30vw;
    width: 30vw;
    margin-bottom: 5vh;
  }
  
  .about_text h1{
    text-align: center;
  }
  
  .about_text a {
    text-decoration: none;
    color: #787878;
  }
  
  .about_resume {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .about_resume a {
    height: 6vmin;
  }

  .about_resume button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2vmax;
  }
  
  .about_resume img{
    margin-bottom: 0;
    height: 0.7em;
    width: auto;
  }
}


@media (max-aspect-ratio: 4/5) {
  .portfolio {
    height: 505vh;
  }
  
  .portfolioBlock {
    width: 100%;
    height: 80vh;

    flex-direction: column;
  }
  
  .portfolioBlock video {
    width: 100%;
    height: auto;
  }

  .portfolioContent {
    width: 100%;
  }

  .portfolioText h1{
    font-size: 5vmax;
  }
  
  .portfolioText p {
    font-size: 3vmax;
  }
  
  .portfolioContent button{
    font-size: 3vmax;
  }

  .about {
    height: 160vh;
  }
  
  .about img {
    height: 40vw;
    width: 40vw;
    margin-bottom: 5vh;
  }

  .about p {
    font-size: 2.5vmax;
    line-height: 3.5vmax;
  }

  .about_resume button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3vmax;
  }
  
  .about_resume img{
    margin-bottom: 0;
    height: 0.7em;
    width: auto;
  }
}